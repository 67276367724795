<!--
 * @Author: wangwei
 * @Date: 2020-12-29 16:05:36
 * @LastEditTime: 2023-01-17 20:42:15
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 充值
 * @FilePath: /java-pc/src/views/member/money/recharge.vue
-->
<template>
    <div class="sld_recharge" v-if="rechargeFlag">
        <MemberTitle :memberTitle="L['账户充值']"></MemberTitle>
        <div class="sld_recharge_con">
            <div class="top_info">
                <div class="progress flex_row_center_center">
                    <div class="step step1 flex_row_start_center">
                        <span class="line"></span>
                        <i class="iconfont icon-querenyuanzhengqueduigoutijiaochenggongwancheng"></i>
                        <span class="step_text">{{ L["1.填写充值金额"] }}</span>
                    </div>
                    <div class="step step2 flex_row_start_center">
                        <span class="no_line" :class="{ sel_line: current_step == 2 }"></span>
                        <i
                            class="iconfont icon-querenyuanzhengqueduigoutijiaochenggongwancheng"
                            :class="{ no_sel: current_step == 1 }"
                        ></i>
                        <span class="no_step_text" :class="{ step_text: current_step == 2 }">{{
                            L["2.在线支付"]
                        }}</span>
                    </div>
                    <div class="step step3 flex_row_start_center">
                        <span class="no_line"></span>
                        <i class="iconfont icon-querenyuanzhengqueduigoutijiaochenggongwancheng no_sel"></i>
                        <span class="no_step_text">{{ L["3.充值完成"] }}</span>
                    </div>
                </div>
            </div>
            <!-- step1  start -->
            <div v-if="current_step == 1" class="step_con step1">
                <div class="title">{{ L["填写充值金额"] }}</div>
                <div class="account">{{ L["充值账户"] }}：{{ store.state.memberInfo.memberMobile }}</div>
                <div class="input_con flex_row_start_center">
                    <span class="input_span">{{ L["充值金额"] }}：</span>
                    <div class="input_con_right">
                        <el-input
                            type="number"
                            class="input"
                            size="medium"
                            v-model="money"
                            placeholder="请输入金额"
                            @blur="moneyInput"
                        >
                        </el-input
                        >元
                        <!-- <div class="info_text">{{ L['请注意：支持支付宝支付、微信支付，在线支付成功后，充值金额会在1到5分钟内到账'] }}</div> -->
                        <div class="next_btn pointer" @click="goStep2">{{ L["下一步"] }}</div>
                    </div>
                </div>
                <div class="tips">
                    <p>{{ L["温馨提示"] }}：</p>
                    <p>{{ L["1.充值成功后，余额可能存在延迟现象，一般1到5分钟内到账，如有问题，请咨询客服；"] }}</p>
                    <p>2.充值金额支持输入6-1000000的的正整数和小数点后2位金额；</p>
                    <p>{{ L["3.充值完成后，您可至会员中心查看充值记录。"] }}</p>
                </div>
            </div>
            <!-- step1  end -->

            <!-- step2  start -->
            <div v-if="current_step == 2" class="reching_tip flex_column_start_start">
                <span>{{
                    show_wx_pay ? L["请使用手机微信扫描下方二维码进行支付"] : L["您正在充值余额，请尽快支付"]
                }}</span>
                <span
                    >{{ show_wx_pay ? L["应付金额"] : L["充值金额"] }}：<span>￥{{ money }} 元</span>
                </span>
            </div>
            <div v-if="current_step == 2" class="step_con step2">
                <span class="method">{{ L["选择充值方式"] }}:</span>
                <div
                    class="select_method flex_row_start_center"
                    v-if="store.state.memberInfo.memberUserType != 0 && current_steps == 1"
                >
                    <div
                        class="item item_s ali flex_row_around_center pointer bankItem bankItem_o"
                        @click="isthadInfo('xx')"
                    >
                        <i
                            :class="{
                                iconfont: true,
                                'icon-yuanquan1': isthad != 'xx',
                                'icon-duihao1': isthad == 'xx'
                            }"
                            style="margin-bottom: 0"
                        ></i>
                        <img src="../../../assets/balance_pay_icon.png" alt />
                        <span>线下转账</span>
                    </div>
                    <div
                        class="item item_s ali flex_row_around_center pointer bankItem bankItem_o"
                        style="width: 200px"
                        @click="isthadInfo('yh')"
                    >
                        <i
                            :class="{
                                iconfont: true,
                                'icon-yuanquan1': isthad != 'yh',
                                'icon-duihao1': isthad == 'yh'
                            }"
                            style="margin-bottom: 0"
                        ></i>
                        <img src="../../../assets/balance_icon.png" alt />
                        <span>企业网银支付</span>
                    </div>
                </div>
                <div
                    v-if="!show_wx_pay && goConfirmFlag && current_steps == 2"
                    class="select_method flex_row_start_center"
                >
                    <!-- <span class="method">{{ L['选择充值方式'] }}:</span> -->
                    <div class="flex_row_start_center bankList">
                        <div
                            v-for="(item, index) in bankList"
                            :key="index"
                            class="item item_s ali flex_row_around_center pointer bankItem bankItem_o"
                            @click="changeBnak(item)"
                            v-if="store.state.memberInfo.memberUserType != 0"
                        >
                            <i
                                :class="{
                                    iconfont: true,
                                    'icon-yuanquan1': ischecked != item.code,
                                    'icon-duihao1': ischecked == item.code
                                }"
                            ></i>
                            <img :src="item.img" alt />
                            <span>{{ item.name }}</span>
                        </div>
                        <div
                            v-for="(item, index) in bankListSelf"
                            :key="index"
                            class="item ali flex_row_between_center pointer bankItem"
                            @click="changeBnakSelf(item)"
                            v-if="store.state.memberInfo.memberUserType == 0"
                        >
                            <!-- <img :src="item.payMethod == 'wx' ? wechat : (item.payMethod == 'alipay' ? ali : '')" alt /> -->
                            <div class="banItem_name pointer">
                                <span>{{ item.firmBankName }}</span>
                                <span>{{
                                    item.firmBankAccount.replace(/^(\d{3})\d+(\d{4})$/, "$1 ********* $2")
                                }}</span>
                            </div>
                            <i
                                :class="{
                                    iconfont: true,
                                    'icon-yuanquan1': ischeckedId != item.id,
                                    'icon-duihao1': ischeckedId == item.id
                                }"
                            ></i>
                        </div>

                        <div class="bankInput bankInputs" v-if="checkItems.data && checkItems.data.isBind == 2">
                            <!-- <input type="text" placeholder="请输入验证码" v-model="payMessage" /> -->
                            <el-input v-model="payMessage" placeholder="请输入验证码" width="120px" />

                            <div class="timeOuts" style="cursor: pointer" @click="getTestNum">
                                {{ descNum ? descNum + "s后获取" : "获取验证码" }}
                            </div>
                        </div>

                        <div
                            v-for="(item, index) in method.data"
                            :key="index"
                            class="item ali flex_row_around_center pointer"
                            @click="changePayMethod(item)"
                            v-if="false"
                        >
                            <i
                                :class="{
                                    iconfont: true,
                                    'icon-yuanquan1': true,
                                    'icon-duihao1': currentMethod.data.payMethod == item.payMethod
                                }"
                            ></i>
                            <img :src="item.payMethod == 'wx' ? wechat : item.payMethod == 'alipay' ? ali : ''" alt />
                            <span>{{ item.payMethodName }}</span>
                        </div>
                    </div>
                </div>
                <div class="info_text2 felx_info_text" v-if="isthad == 'xx'">
                    <div class="info_text2_box">
                        请使用已绑定账户信息进行线下汇款充值，系统在收到汇款后将自动充值到您的钱包
                    </div>
                    <div style="font-weight: bold">收款账户信息：</div>
                    <div>收款户名：平台交易资金待清算专户（大石工采）</div>
                    <div>收款账号：15000109875307</div>
                    <div>开户银行：平安银行</div>
                </div>
                <div
                    v-if="!show_wx_pay && goConfirmFlag && current_steps == 1 && isthad != 'xx'"
                    class="pay_btn pointer"
                    @click="goStep2(1)"
                >
                    下一步
                </div>
                <div
                    v-if="!show_wx_pay && goConfirmFlag && current_steps == 2 && isthad != 'xx'"
                    class="pay_btn pointer"
                    @click="goPay"
                >
                    {{ L["立即充值"] }}
                </div>
                <div class="tips2" v-if="goConfirmFlag">
                    <p>{{ L["温馨提示"] }}：</p>
                    <p>{{ L["1.充值成功后，余额可能存在延迟现象，一般1到5分钟内到账，如有问题，请咨询客服；"] }}</p>
                    <p>2.充值金额支持输入6-1000000的的正整数和小数点后2位金额；</p>
                    <p>{{ L["3.充值完成后，您可至会员中心查看充值记录。"] }}</p>
                </div>
                <div style="height: 1px"></div>
                <!-- 微信支付 start-->
                <!-- <div v-if="show_wx_pay" class="wx_pay_con">
          <div class="wx_pay_info">
            <span class="pay_type">{{ L['微信支付支付'] }}</span>
            <span>
              {{ L['如二维码过期，'] }}
              <span class="red" @click="wxPayRefresh" style="cursor: pointer;">{{ L['刷新'] }}</span>{{ L['重新获取二维码。'] }}
            </span>
          </div>
          <div class="qrcode_con flex_row_start_center">
            <div class="left flex_column_start_center">
              <div class="qrcode_image flex_row_center_center">
                <img :src="wxPayQrImg" alt="">
              </div>
              <div class="qrcode_image_tips flex_row_center_center">
                <i class="iconfont icon-saoma"></i>
                <span>{{ L['使用微信扫码支付'] }}</span>
              </div>
            </div>
            <div class="right_tip">
              <img src="@/assets/member/wx_tips.png" alt />
            </div>
          </div>
        </div> -->
                <!-- 微信支付 end-->
                <!-- 协议支付start -->
                <el-form :model="bankForm" label-width="120px" v-if="!goConfirmFlag" style="margin-top: 20px">
                    <el-form-item label="支付密码">
                        <el-col :span="7" style="float: none">
                            <el-input placeholder="请输入支付密码" v-model="payPwd" type="password" />
                        </el-col>
                    </el-form-item>
                    <el-form-item label="短信验证码">
                        <el-col :span="7" style="float: none">
                            <el-input v-model="messageCheckCode" placeholder="请输入短信验证码" width="120px" />
                            <!-- <div class="timeOut" @click="sendMsg">获取验证码</div> -->
                            <div class="timeOut" style="cursor: pointer" @click="getTestNums">
                                {{ descNum2 ? descNum2 + "s后获取" : "获取验证码" }}
                            </div>
                            <!-- <div class="timeOut">120s后获取</div> -->
                        </el-col>
                    </el-form-item>
                    <el-form-item label="手机号">
                        <el-col :span="7" style="float: none">
                            <div>{{ rechmoile }}</div>
                        </el-col>
                    </el-form-item>
                    <div class="activation" @click="activationCard" style="cursor: pointer">立即支付</div>
                </el-form>
                <!-- 协议支付ende -->
            </div>
            <!-- step2  end -->
        </div>
    </div>
</template>

<script>
import { ElInput, ElMessage } from "element-plus";
import { getCurrentInstance, ref, watch, reactive, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Encrypt, Decrypt } from "../../../utils/common.js";
import CryptoJS from "crypto-js";
import MemberTitle from "../../../components/MemberTitle";
import { platform } from "@/utils/config";

export default {
    name: "Recharge",
    components: {
        ElInput,
        MemberTitle
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        // const route = useRoute();
        const { proxy } = getCurrentInstance();
        const L = proxy.$getCurLanguage();
        const saoma = require("../../../assets/coupon/saoma.png");
        const ali = require("../../../assets/buy/ali.png");
        const wechat = require("../../../assets/buy/wechat.png");
        const wx_pay_tip = require("../../../assets/coupon/phone-bg.png");
        const money = ref("");
        const current_step = ref(1);
        const method = reactive({ data: [] });
        const memberInfo = ref(store.state.memberInfo);
        const payMessage = ref("");
        const messageCheckCode = ref(""); //支付验证码
        const payPwd = ref();
        const isthad = ref("");
        const current_steps = ref(1);
        const rechargeFlag = ref(false);
        const currentMethod = reactive({
            data: {}
        });
        const show_wx_pay = ref(false);
        const wxPayQrImg = ref("");
        const timer = ref(0); //定时器
        const rechargeId = ref("");
        const goConfirmFlag = ref(true); //去协议支付
        const goStep2 = (type) => {
            if (type == 1) {
                if (!isthad.value) {
                    ElMessage.warning("请选择支付方式");
                    return;
                }
                if (isthad.value == "yh") {
                    current_step.value = 2;
                    current_steps.value = 2;
                    return;
                }
                return;
            }
            if (bankListSelf.value.length == 0 && userType.data.memberUserType == 0) {
                router.push("/member/cardBind");
            }
            if (bankListSelf.value.length == 0 && userType.data.memberUserType != 0) {
                ElMessage.warning("请先去绑定银行卡");
                return;
            }
            if (money.value == "") {
                ElMessage.warning(L["请输入充值金额"]);
                return;
            } else if (money.value > 1000000) {
                ElMessage.warning(L["超过充值金额上限"]);
                return;
            }
            if (store.state.memberInfo.memberUserType != 0) {
                if (money.value < 6) {
                    ElMessage.warning("至少6元起充");
                    return;
                }
            }
            if (store.state.memberInfo.memberUserType != 0) {
                current_step.value = 2;
                current_steps.value = 1;
            } else {
                current_step.value = 2;
                current_steps.value = 2;
            }
        };
        //获取充值方式
        //const getRechargeMethod = () => {
        //  proxy
        //    .$get("v3/business/front/orderPay/payMethod", {
        //      source: "pc",
        //      type: 2
        //    })
        //    .then(res => {
        //      if (res.state == 200) {
        //        method.data = res.data;
        //        if (method.data.length > 0) {
        //          currentMethod.data = method.data[0]
        //        }

        //      } else {
        //        ElMessage(res.msg);
        //      }
        //    })
        //    .catch(() => {
        //      //异常处理
        //    });
        //};
        //切换充值方式
        const changePayMethod = (item) => {
            currentMethod.data = item;
        };

        const moneyInput = () => {
            // money.value.replace(/\D/g, "")
            if (money.value.slice(0, 4) < 6) {
                money.value = 6;
            } else {
                //是否为小数
                if (!Number.isInteger(Number(money.value))) {
                    money.value = Number(money.value).toFixed(2);
                }
            }
        };

        const wxPayRefresh = () => {
            goPay();
        };
        //去支付
        const goPay = () => {
            let param = {};
            if (memberInfo.value.memberUserType != 0) {
                if (!ischecked.value) {
                    ElMessage("请选择银行");
                    return;
                }
                //param.payType = 'B2B';
                param.payMethod = "B2B";
                param.amount = Number(money.value);
                param.bankCode = bankCode.value;
                param.bankName = bankName.value;
                param.uscUserId =
                    store.state.memberInfo.memberUserType == 2
                        ? store.state.memberInfo.uscUserPid
                        : store.state.memberInfo.uscUserId;

                //请求支付接口
                proxy
                    .$post("api/pay/wallet/ebank/recharge", param, "json")
                    .then((res) => {
                        if (res.code == 200) {
                            document.write(res.data);
                            if (param.payMethod == "alipay") {
                                document.write(res.data); //自动提交表单数据
                            } else if (param.payMethod == "wx") {
                                // rechargeId.value = res.data.rechargeId;
                                // show_wx_pay.value = true;
                                // wxPayQrImg.value = "data:image/png;base64," + res.data.payData; //微信支付二维码
                                // 定时查询是否支付成功
                                timer.value = setInterval(() => {
                                    queryPayState();
                                }, 3000);
                                //两小时自动跳转充值列表
                                setTimeout(() => {
                                    router.replace({
                                        path: "/member/balance"
                                    });
                                }, 432000000);
                            }
                        } else {
                            ElMessage(res.msg);
                        }
                    })
                    .catch(() => {
                        //异常处理
                    });
            } else {
                // if (!ischeckedId.value) {
                //   ElMessage.warning('请选择银行卡');
                //   return;
                // }
                // param.payType = currentMethod.data.payType;
                // param.payMethod = currentMethod.data.payMethod;
                // param.amount = money.value;

                // proxy
                // .$post("v3/member/front/balanceRecharge/payCloud", param)
                // .then(res => {
                //   if (res.state == 200) {
                //     document.write(res.data.payData);
                //     if (param.payMethod == "alipay") {
                //       document.write(res.data.payData); //自动提交表单数据
                //     } else if (param.payMethod == "wx") {
                //       // rechargeId.value = res.data.rechargeId;
                //       // show_wx_pay.value = true;
                //       // wxPayQrImg.value = "data:image/png;base64," + res.data.payData; //微信支付二维码
                //       // 定时查询是否支付成功
                //       timer.value = setInterval(() => {
                //         queryPayState();
                //       }, 3000);
                //       //两小时自动跳转充值列表
                //       setTimeout(() => {
                //         router.replace({
                //           path: "/member/balance"
                //         });
                //       }, 432000000);
                //     }
                //   } else {
                //     ElMessage(res.msg);
                //   }
                // })
                if (!ischeckedId.value) {
                    ElMessage.warning("请选择银行卡");
                    return;
                }
                // goConfirmFlag.value = false
                if (checkItems.data.isBind == 2) {
                    if (!payMessage.value) {
                        ElMessage.warning("请输入验证码");
                        return;
                    }
                    let params = {};
                    params.card = { mobileCode: payMessage.value };
                    params.bankCardId = ischeckedId.value;
                    proxy.$post("api/pay/pingan/bank/agreePaySignature", params, "json").then((res) => {
                        if (res.code == 200) {
                            // descNum.value = 60
                            ElMessage.success(res.msg);
                            goConfirmFlag.value = false;
                        } else {
                            ElMessage.warning(res.msg);
                        }
                    });
                } else {
                    goConfirmFlag.value = false;
                }
            }
        };
        // 定时查询是否支付成功
        const queryPayState = () => {
            getPayInfo();
        };
        //获取充值明细   --用于判断充值是否成功
        const getPayInfo = () => {
            proxy
                .$get("api/plugin/mb/wallet/price1", {
                    ///旧 v3/member/front/balanceRecharge/detail
                    rechargeId: rechargeId.value
                })
                .then((res) => {
                    if (res.code == 200) {
                        if (res.data.payState == 2) {
                            //清除定时器
                            if (timer.value) {
                                clearInterval(timer.value);
                                timer.value = 0;
                            }
                            console.log(window.history.state.back, "oooooo");
                            if (window.history.state.back == "/member/balance" || !window.history.state.back) {
                                ElMessage.success(L["充值成功,2s后自动跳转充值列表"]);
                                setTimeout(() => {
                                    router.replace({
                                        path: "/member/balance"
                                    });
                                }, 2000);
                            } else {
                                ElMessage.success(L["充值成功,2s后自动跳转支付页面"]);
                                setTimeout(() => {
                                    router.go(-1);
                                }, 2000);
                            }
                        }
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };
        watch(money, (newVal, oldVal) => {
            if (newVal == "") {
                money.value = "";
                return;
            }
            // var reg = /^[1-9]\d*$/;
            // if (!reg.test(newVal)) {
            //   console.log(oldVal);
            //   money.value = oldVal;
            // }
            if (newVal < 0) {
                money.value = "";
            }
        });
        // 获取银行列表
        const bankNum = ref(); //银行卡号
        let bankList = ref([]);
        const getBankList = () => {
            proxy.$get("api/pay/common/queryBankB2b").then((res) => {
                if (res.code == 200) {
                    // ElMessage.success(res.msg)
                    res.data.forEach((item) => {
                        item.img = require(`../../../assets/bank/${item.code}.png`);
                    });
                    bankList.value = res.data;
                    console.log(bankList.value);
                } else {
                    ElMessage.warning(res.msg);
                }
            });
        };
        getBankList();
        const ischecked = ref("");
        const bankCode = ref(null); //bankcode
        const bankName = ref("");
        const changeBnak = (item) => {
            ischecked.value = item.code;
            bankCode.value = item.code;
            bankName.value = item.name;
        };
        const ischeckedId = ref("");
        const checkItems = reactive({
            data: {
                isBind: -1
            }
        });
        const changeBnakSelf = (item) => {
            console.log(item, 56565);
            ischeckedId.value = item.id;
            checkItems.data = item;
        };
        // 获取银行列表 end
        onMounted(() => {
            // getRechargeMethod();
        });
        onUnmounted(() => {
            if (timer.value) {
                clearInterval(timer.value);
                timer.value = 0;
            }
        });
        // 个人银行卡列表
        const bankListSelf = ref([]);
        const getBankInfo = async () => {
            proxy.$get("api/shopp/member/bank/card/list").then((res) => {
                if (res.code == 200) {
                    // ElMessage.success(res.msg)
                    // console.log(res)
                    bankListSelf.value = res.data;
                } else {
                    ElMessage.warning(res.msg);
                }
            });
        };
        getBankInfo();
        // 获取验证码
        const descNum = ref(0);
        const descNum2 = ref(0);
        const descNum3 = ref(0);
        let isFlag = ref(false);
        const getTestNum = () => {
            if (isFlag.value == true) {
                return;
            }
            if (descNum.value) {
                return;
            }
            isFlag.value = true;
            // const timedesc = setInterval(() => {
            //   descNum.value -= 1
            // }, 1000);
            // setTimeout(() => {
            //   isFlag.value = false
            //   descNum.value = 60
            //   clearInterval(timedesc)
            // }, 6000)

            let param = {};
            param.bankCardId = checkItems.data.id;
            // param.amount = money.value;
            // params.
            proxy.$post("api/pay/pingan/bank/agreePaySignatureSendSMS", param, "json").then((res) => {
                if (res.code == 200) {
                    ElMessage.success(res.msg);
                    if (res.rechargeId) {
                        rechargeId.value = res.rechargeId;
                    }
                    descNum.value = 120;
                    countDown();
                    isFlag.value = false;
                } else {
                    ElMessage.warning(res.msg);
                    isFlag.value = false;
                }
            });
        };
        const timeOutId = ref(""); //定时器的返回值
        const timeOutId2 = ref(""); //定时器的返回值
        const timeOutId3 = ref(""); //定时器的返回值
        //倒计时
        const countDown = () => {
            descNum.value--;
            if (descNum.value == 0) {
                isFlag.value = false;
                clearTimeout(timeOutId.value);
            } else {
                timeOutId.value = setTimeout(countDown, 1000);
            }
        };
        const countDown2 = () => {
            descNum2.value--;
            if (descNum2.value == 0) {
                isFlag.value = false;
                clearTimeout(timeOutId2.value);
            } else {
                timeOutId2.value = setTimeout(countDown2, 1000);
            }
        };
        const countDown3 = () => {
            descNum3.value--;
            if (descNum3.value == 0) {
                isFlag.value = false;
                clearTimeout(timeOutId3.value);
            } else {
                timeOutId3.value = setTimeout(countDown3, 1000);
            }
        };
        // 验证吗支付
        const reId = ref();
        const getTestNums = () => {
            if (isFlag.value == true) {
                return;
            }
            if (descNum2.value) {
                return;
            }
            isFlag.value = true;
            // const timedesc = setInterval(() => {
            //   descNum2.value -= 1
            // }, 1000);
            // setTimeout(() => {
            //   isFlag.value = false
            //   descNum2.value = 60
            //   clearInterval(timedesc)
            // }, 6000)
            let param = {};
            // param.paySn = paySn.value
            console.log(checkItems.data.cardId, 6666);
            param.bankCardId = checkItems.data.id;
            param.tranAmt = money.value;
            // param.password = payPwd.value
            proxy.$post("api/pay/pingan/bank/sendShortMessage", param, "json").then((res) => {
                if (res.code == 200) {
                    ElMessage.success(res.msg);
                    descNum2.value = 120;
                    isFlag.value = false;
                    countDown2();
                    reId.value = res.data;
                } else {
                    ElMessage.warning(res.msg);
                    isFlag.value = false;
                }
            });
        };
        const activationCard = () => {
            if (!payPwd.value) {
                ElMessage.warning("请输入支付密码");
                return;
            }
            if (!messageCheckCode.value) {
                ElMessage.warning("请输入验证码");
                return;
            }
            let param = {};
            param.tranAmt = money.value;
            param.banCardId = ischeckedId.value;
            param.password = Encrypt(payPwd.value);
            param.code = messageCheckCode.value;
            proxy.$post("api/pay/pingan/bank/agreeRechargePay", param, "json").then((res) => {
                if (res.code == 200) {
                    ElMessage.success(res.msg);
                    setTimeout(() => {
                        router.push("/member/balance");
                    }, 3000);
                } else {
                    ElMessage.warning(res.msg);
                    setTimeout(() => {
                        router.go(0);
                    }, 2000);
                }
            });
        };
        // 获取验证码
        // const getTestNumss = () => {
        //   if (isFlag.value == true) {
        //     return
        //   }
        //   isFlag.value = true
        //   const timedesc = setInterval(() => {
        //     descNum3.value -= 1
        //   }, 1000);
        //   setTimeout(() => {
        //     isFlag.value = false
        //     descNum3.value = 60
        //     clearInterval(timedesc)
        //   }, 6000)

        //   let params = {}
        //   params = bankForm.value
        //   // params.
        //   proxy.$post('/v3/member/front/balanceRecharge/sendSMS', params).then(res => {
        //     if (res.state == 200) {
        //       ElMessage.success(res.msg)
        //     } else {
        //       ElMessage.warning(res.msg)
        //     }
        //   })

        // }
        const rechmoile = ref("");
        const userType = reactive({ data: {} });
        const getInfo = () => {
            proxy.$get("api/shopp/member/detail", { uscUserId: store.state.memberInfo.uscUserId }).then((res) => {
                if (res.code == 200) {
                    store.commit("updateMemberInfo", res.data);
                    userType.data = res.data;
                    if (!res.data.isLookWallet) {
                        ElMessage.warning("暂无权限访问");
                        setTimeout(() => {
                            router.push(
                                {
                                    path: "/member/index"
                                },
                                700
                            );
                        });
                        return;
                    }
                    if (!res.data.createWallet && res.data.memberUserType == 0) {
                        rechargeFlag.value = false;
                        router.push("/member/AccountNow");
                        // router.push('/member/AccountNow')
                    } else {
                        rechargeFlag.value = true;
                        rechmoile.value = res.data.memberMobile;
                    }
                }
            });
        };
        const account_list = reactive({
            account_number: "",
            bank_name: "",
            company_name: ""
        });
        const get_account = () => {
            proxy.$get("v3/member/front/member/memberInfo", {}).then((res) => {
                if (res.code == 200) {
                    account_list.account_number = res.data.memberExtend.enterpriseName;
                    account_list.bank_name = res.data.memberExtend.enterpriseBankAccount;
                    account_list.company_name = res.data.memberExtend.enterpriseBankOpenAddress;
                }
            });
            // proxy.$get('v3/system/front/setting/getSettings', { names: 'admin_bank_account_number,admin_bank_name,admin_company_name' }).then(res => {
            //     if (res.state == 200) {
            //       account_list.account_number = res.data[0]
            //       account_list.bank_name = res.data[1]
            //       account_list.company_name = res.data[2]
            //     }
            // })
        };
        onMounted(() => {
            getInfo();
            // get_account()
        });
        const isthadInfo = (type) => {
            isthad.value = type;
        };
        return {
            L,
            money,
            current_step,
            ali,
            wechat,
            isthadInfo,
            goStep2,
            method,
            userType,
            rechmoile,
            changePayMethod,
            currentMethod,
            goPay,
            show_wx_pay,
            saoma,
            wx_pay_tip,
            wxPayQrImg,
            store,
            wxPayRefresh,
            moneyInput,
            bankNum,
            bankList,
            changeBnak,
            ischecked,
            bankListSelf,
            ischeckedId,
            changeBnakSelf,
            descNum,
            descNum2,
            descNum3,
            isFlag,
            getTestNum,
            payMessage, //验证码，
            // getTestNumss,
            goConfirmFlag, //去协议支付
            messageCheckCode,
            getInfo,
            getTestNums,
            activationCard,
            payPwd,
            countDown,
            timeOutId,
            timeOutId2,
            timeOutId3,
            countDown2,
            countDown3,
            checkItems,
            isthad,
            current_steps,
            rechargeFlag,
            bankName,
            account_list
        };
    }
};
</script>

<style lang="scss" scoped>
// .felx_info_text {
//   display: flex;
//   flex-direction: column;
// }

.activation {
    width: 170px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    background-color: $colorMain;
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    text-align: center;
    font-family: Microsoft Yahei;
    margin-top: 79px;
    margin-left: 154px;
}
.timeOut {
    position: absolute;
    top: 0px;
    left: 158px;
    width: 80px;
    height: 40px;
    border-radius: 0px 4px 4px 0px;
    background-color: $colorMain2;
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    text-align: center;
    line-height: 40px;
}
.timeOuts {
    width: 109px;
    height: 35px;
    border-radius: 4px;
    background-color: $colorMain;
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    text-align: center;
    line-height: 35px;
    margin-left: 10px;
}

.bankList {
    flex-wrap: wrap;

    .timeOut {
        // position: absolute;
        right: -110px;
        top: -5px;
        width: 99px;
        height: 25px;
        border-radius: 4px;
        background-color: rgba(240, 39, 39, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        text-align: center;
        line-height: 25px;
    }

    .bankItem {
        position: relative;
        margin-bottom: 40px;
    }
}

.bankInput {
    padding-left: 3px;
    // position: absolute;
    // bottom: -30px;
    // left: 0;
    display: flex;
    align-items: center;
}
.bankInputs {
    margin-left: 18px;
}

.banItem_name {
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.info_text2 {
    color: #999;
    font-size: 12px;
    margin-left: 20px;

    div {
        margin-bottom: 10px;
        color: #000;
    }
}
.info_text2_box {
    margin-top: 20px;
    margin-bottom: 20px !important;
    color: #999 !important;
}
</style>

<style lang="scss">
@import "../../../style/recharge.scss";

.el-input.is-active .el-input__inner,
.el-input__inner:focus {
    border-color: $colorMain;
    outline: 0;
}

/**
* 解决el-input设置类型为number时，中文输入法光标上移问题
**/
.el-input__inner {
    line-height: 1px !important;
}
</style>
